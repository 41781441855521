import './webpack-imports';
import './sentry';
import barba from '@barba/core';
import './metrics';
import gsap from 'gsap';
import IndexPageView from './views/index';
import { initCustomElements } from './custom-elements';
import { applyPolyfills } from './polyfills';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import BaseTransition from './transitions/base';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import { initWorklets } from './inits/worklets';
import ajaxForms from './inits/ajax-forms';
import popupForms from './inits/popup-forms';
import { Cursor } from './inits/cursor';
import { createInitialBaseTimeline } from './animations/initial-base';
import { appQueue } from './app-queue';
import type { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';

document.documentElement.classList.add('js-ready');

if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}

applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    appQueue.pushTask(initCustomElements);
    appQueue.pushTask(calculateScrollbarWidth);
    appQueue.pushTask(initWorklets);
    initBaseView();

    appQueue.pushTask(() => {
        barba.init({
            transitions: [BaseTransition],
            views: [
                IndexPageView,
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                ),
                lazyView('catalog-page', () =>
                    import(/* webpackChunkName: "catalog-page" */ './views/catalog').then((m) => m.default),
                ),
                lazyView('webinars-page', () =>
                    import(/* webpackChunkName: "webinars-page" */ './views/webinars').then((m) => m.default),
                ),
                lazyView('article-page', () =>
                    import(/* webpackChunkName: "article-page" */ './views/article').then((m) => m.default),
                ),
                lazyView('direction-page', () =>
                    import(/* webpackChunkName: "direction-page" */ './views/direction').then((m) => m.default),
                ),
                lazyView('raw-detail-page', () =>
                    import(/* webpackChunkName: "raw-detail-page" */ './views/raw-detail').then((m) => m.default),
                ),
                lazyView('lab-detail-page', () =>
                    import(/* webpackChunkName: "lab-detail-page" */ './views/lab-detail').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            // prevent: ({ el }) => el.tagName === 'A' && !!el.closest('#bx-panel'),
            prevent: ({ el }) => el.classList.contains('chps-content-list-pagination-link'),
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });
    });

    appQueue.pushTask(() => {
        const menu = document.querySelector<LitPopupElement>('[data-lit-popup="menu"]');
        const feedbackPopup = document.querySelector<LitPopupElement>('[data-lit-popup="feedback"]');

        const NO_SCROLL_CLASS = 'no-scroll';

        function _lockBodyScroll() {
            document.body.classList.add(NO_SCROLL_CLASS);
        }

        function _unlockBodyScroll() {
            document.body.classList.remove(NO_SCROLL_CLASS);
        }

        const popups = [menu, feedbackPopup];

        popups.forEach((el) => {
            if (el) {
                let openButtons: HTMLElement[] = [];
                const popupName = el.dataset.litPopup || '';

                el.addEventListener('open', () => {
                    setTimeout(() => {
                        Array.from(
                            document.querySelectorAll<HTMLElement>(`[data-lit-popup-open="${popupName}"]`),
                        ).forEach((_el) => {
                            _el.removeAttribute('data-lit-popup-open');
                            _el.setAttribute('data-lit-popup-close', popupName);
                            openButtons.push(_el);
                        });
                    }, 0);
                });

                el.addEventListener('close-complete', () => {
                    openButtons.forEach((_el) => {
                        _el.removeAttribute('data-lit-popup-close');
                        _el.setAttribute('data-lit-popup-open', popupName);
                    });
                    openButtons = [];
                });

                barba.hooks.leave(() => {
                    if (el?.instance.isOpen) {
                        el.close();
                    }
                });
            }
        });

        if (menu) {
            menu.addEventListener('open', () => {
                if (feedbackPopup?.instance.isOpen) {
                    feedbackPopup.close();
                }
            });
        }

        if (feedbackPopup) {
            ajaxForms.init(feedbackPopup);
            popupForms.init(feedbackPopup);

            feedbackPopup.addEventListener('open', () => {
                if (menu?.instance.isOpen) {
                    menu.close();
                }
            });
        }

        if (menu) {
            menu.addEventListener('open', () => {
                if (feedbackPopup?.instance.isOpen) {
                    feedbackPopup.close();
                }
            });
        }
    });

    appQueue.pushTask(() => {
        createInitialBaseTimeline().play();
    });

    appQueue.pushTask(() => {
        Cursor().init();
    });

    function setVisibilityState() {
        if (document.visibilityState === 'hidden') {
            document.documentElement.classList.add('is-document-hidden');
        } else {
            document.documentElement.classList.remove('is-document-hidden');
        }
    }

    setVisibilityState();
    document.addEventListener('visibilitychange', setVisibilityState);
});
