const observer = new IntersectionObserver((entries, obs) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            obs.unobserve(entry.target);
            entry.target.classList.add('is-revealed');
        }
    });
});

function init(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLElement>(
            '[data-reveal], .js-article-content.wysiwyg > *, .js-article-image-section.wysiwyg > *',
        ),
    );
    elements.forEach((el) => {
        observer.observe(el);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLElement>(
            '[data-reveal], .js-article-content.wysiwyg > *, .js-article-image-section.wysiwyg > *',
        ),
    );
    elements.forEach((el) => {
        observer.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
