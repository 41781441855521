import type { Swiper as SwiperType } from 'swiper';
import { fetchSwiper } from '../dynamic-modules';
import { createNav, destroyNav, map } from '../components/base-sliders';
import { Stack } from '../components/stack';
// import { executeOnIntersection } from '../utils/execute-on-intersection';

const cardSlidersMap = new WeakMap<HTMLElement, () => void>();

function destroySliderByElement(el: HTMLElement) {
    const slider = map.get(el);
    const resizeFn = cardSlidersMap.get(el);

    if (resizeFn) {
        el.removeEventListener('resize', resizeFn);
        cardSlidersMap.delete(el);
    }

    if (slider) {
        slider.destroy();
        map.delete(el);
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-card-slider')).forEach((el) => {
        const onResize = async () => {
            const sliderInstance = map.get(el);

            if (matchMedia('(min-width: 768px)').matches) {
                if (!sliderInstance || sliderInstance instanceof Stack) {
                    if (sliderInstance) {
                        sliderInstance.destroy();
                        map.delete(el);
                    }

                    const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

                    if (slides.length > 1) {
                        const { Swiper, Lazy, Autoplay } = await fetchSwiper();
                        const sliderBlock = el.closest<HTMLElement>('.js-slider-block');
                        const sliderNav = sliderBlock?.querySelector<HTMLElement>('.js-slider-nav');

                        let hold = false;
                        let isTransitionSet = false;
                        let timer: NodeJS.Timeout;

                        const onMousemove = () => {
                            if (!hold || isTransitionSet) return;

                            timer = setTimeout(() => {
                                // eslint-disable-next-line no-use-before-define
                                slider.el.style.setProperty('--transition-duration-active', '0s');
                            }, 150);

                            isTransitionSet = true;
                        };

                        const onMouseup = () => {
                            clearTimeout(timer);
                            hold = false;
                            isTransitionSet = false;
                            // eslint-disable-next-line no-use-before-define
                            slider.el.style.removeProperty('--transition-duration-active');
                            document.removeEventListener('mousemove', onMousemove);
                            document.removeEventListener('mouseup', onMouseup);
                        };

                        const onMousedown = () => {
                            hold = true;
                            document.addEventListener('mousemove', onMousemove);
                            document.addEventListener('mouseup', onMouseup);
                        };

                        const setTranslate = (swiper: SwiperType, wrapperTranslate: number) => {
                            const LAYOUT_COLS = 6;
                            const LAYOUT_GRID_SIZE = 44;
                            const size = (swiper as any).size * (LAYOUT_COLS / LAYOUT_GRID_SIZE);
                            const _slides = Array.from(swiper.slides);

                            for (let i = 0; i < _slides.length; i++) {
                                const slide = _slides[i] as HTMLElement;
                                slide.style.transform = `translate3d(${
                                    Math.max(-(slide as any).swiperSlideOffset + size, wrapperTranslate) - size
                                }px, 0, 0)`;
                            }
                        };

                        const slider = new Swiper(el, {
                            modules: [Lazy],
                            slidesPerView: 1,
                            watchSlidesProgress: true,
                            virtualTranslate: true,
                            speed: 0,
                            preloadImages: false,
                            lazy: {
                                loadPrevNext: true,
                                checkInView: true,
                            },
                            on: {
                                beforeInit: (swiper) => {
                                    createNav(swiper, sliderNav);
                                },
                                init: (swiper) => {
                                    swiper.el.addEventListener('mousedown', onMousedown);
                                    setTranslate(swiper, 0);
                                    sliderBlock?.classList.add('slider-block-initialized');
                                },
                                destroy: (swiper) => {
                                    destroyNav(el);
                                    swiper.el.removeEventListener('mousedown', onMousedown);
                                    document.removeEventListener('mousemove', onMousemove);
                                    document.removeEventListener('mouseup', onMouseup);
                                    clearTimeout(timer);
                                    hold = false;
                                    isTransitionSet = false;
                                    sliderBlock?.classList.remove('slider-block-initialized');
                                },
                                setTranslate: (swiper, translate) => {
                                    setTranslate(swiper, translate);
                                },
                            },
                        });
                        map.set(el, slider);
                    }
                }
            } else if (matchMedia('(max-width: 815px) and (orientation: landscape)').matches) {
                if (sliderInstance instanceof Stack) {
                    sliderInstance.destroy();
                    map.delete(el);
                }
            } else {
                const stacksBlock = el.querySelector<HTMLElement>('.js-card-slider-wrapper');

                if (sliderInstance) {
                    destroySliderByElement(el);
                }

                if (stacksBlock) {
                    const stack = new Stack(stacksBlock, 'bottom bottom');
                    map.set(el, stack);
                }
            }
        };

        // executeOnIntersection(el, onResize, { rootMargin: '200px 0px 200px 0px' });
        onResize();
        window.addEventListener('resize', onResize);
        cardSlidersMap.set(el, onResize);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-card-slider')).forEach((el) => {
        const sliderInstance = map.get(el);

        if (sliderInstance) {
            if (sliderInstance instanceof Stack) {
                sliderInstance.destroy();
            } else {
                destroySliderByElement(el);
            }
        }
    });
}

const _module = { init, destroy };

export default _module;
