import gsap from 'gsap';
import splittedText from './splitted-text';

export function createInitialCommonTimeline(container: HTMLElement | Document = document) {
    const splitted = Array.from(container.querySelectorAll<HTMLElement>('.js-initial-animation-splitted'));
    const tl = gsap.timeline({ paused: true, defaults: { duration: 0.7, ease: 'power3.out' }, onComplete: () => {
        document.dispatchEvent(new Event('common-animation-complete'))
    } });

    tl.fromTo(
        '.js-initial-animation-section',
        { y: 30, opacity: 0 },
        {
            y: 0,
            opacity: 1,
            stagger: 0.1,
        },
    );
    splitted.forEach((el, i) => {
        const splittedTextTl = el ? splittedText(el) : gsap.timeline();
        tl.add(splittedTextTl.play(), 0.2 + 0.15 * i);
    });
    tl.fromTo('.js-initial-animation-text', { opacity: 0, y: 30 }, { opacity: 1, y: 0, stagger: 0.1 }, 0.3);

    return tl;
}
