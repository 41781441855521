import type { Swiper as SwiperType } from 'swiper';
import { html, render, RootPart } from 'lit';
import { fetchSwiper } from '../dynamic-modules';
import { createNav, destroyNav } from '../components/base-sliders';
// import { executeOnIntersection } from '../utils/execute-on-intersection';

const map = new Map<Element, any>();

function createBottomNav(swiper: SwiperType, sliderNav: HTMLElement) {
    const titles = swiper.slides.map(
        (slide) => slide.querySelector('.js-story-slide-title')?.textContent?.trim() || '',
    );

    return () =>
        render(
            html`
                <button
                    class="story-slider-bottom-nav-item story-slider-bottom-nav-item--prev"
                    @click="${() => swiper.slidePrev()}"
                >
                    <span class="story-slider-bottom-nav-item__text">${titles[swiper.activeIndex - 1] || ''}</span>
                </button>
                <button
                    class="story-slider-bottom-nav-item story-slider-bottom-nav-item--next"
                    @click="${() => swiper.slideNext()}"
                >
                    <span class="story-slider-bottom-nav-item__text">${titles[swiper.activeIndex + 1] || ''}</span>
                </button>
            `,
            sliderNav,
        );
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-story-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Lazy, Pagination } = await fetchSwiper();
            const sliderBlock = el.closest<HTMLElement>('.js-slider-block');
            const sliderNav = sliderBlock?.querySelector<HTMLElement>('.js-slider-nav');
            const sliderBottomNav = el.querySelector<HTMLElement>('.js-story-slider-nav');
            let renderBottomNav: (() => RootPart) | null;

            // executeOnIntersection(
            //     el,
            //     () => {
            const slider = new Swiper(el, {
                modules: [Lazy, Pagination],
                slidesPerView: 'auto',
                loopedSlides: 2,
                loop: true,
                shortSwipes: false,
                longSwipesMs: 50,
                longSwipesRatio: 0.15,
                centeredSlides: true,
                speed: 500,
                watchSlidesProgress: true,
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 2,
                    checkInView: true,
                    loadOnTransitionStart: true,
                },
                on: {
                    beforeInit: (swiper) => {
                        createNav(swiper, sliderNav);
                    },
                    init: (swiper) => {
                        if (sliderBottomNav) {
                            renderBottomNav = createBottomNav(swiper, sliderBottomNav);
                            renderBottomNav();
                        }

                        sliderBlock?.classList.add('slider-block-initialized');
                    },
                    slideChange: () => {
                        renderBottomNav?.();
                    },
                    destroy: () => {
                        destroyNav(el);
                        sliderBlock?.classList.remove('slider-block-initialized');
                    },
                },
            });
            map.set(el, slider);
            //     },
            //     { rootMargin: '200px 0px 200px 0px' },
            // );
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-story-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
