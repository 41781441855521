import { clearInputs } from '../../modules/ajax-form-sender';
import { clearMessages } from '../../modules/validator/utils';
import { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

function clearForm(form: HTMLFormElement) {
    const inputs = Array.from(
        form.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
            'input[name]:not([type="submit"]):not([type="reset"]), select[name], textarea[name]',
        ),
    );

    clearInputs(inputs);
    inputs.forEach((input) => input.classList.remove('is-error'));
    clearMessages(form);
}

function init(container: HTMLElement | Document = document) {
    const forms = Array.from(
        container.querySelectorAll<HTMLFormElement>('form.js-ajax-form[data-clear-on-popup-close]'),
    );

    forms.forEach((form) => {
        const popupElement = form.closest<LitPopupElement>('app-lit-popup');
        if (popupElement) {
            popupElement.instance.on('close', () => {
                clearForm(form);
            });
        }
    });
}

const _module = { init };

export default _module;
