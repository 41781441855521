import type { IView } from '@barba/core';
import gsap from 'gsap';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { IdleValue } from 'idlize/IdleValue.mjs';
import LazyLoad from 'vanilla-lazyload';
import cardSliders from '../inits/card-sliders';
import { createInitialMainPageTimeline } from '../animations/initial-main-page';
import type { LitPopupElement } from '../custom-elements/LitPopupElement/LitPopupElement';

const beforeEnterQueue = new IdleQueue();
const footer = document.querySelector<HTMLElement>('.js-footer');
const main = document.querySelector<HTMLElement>('.js-main');
let enterTl: any;
let mainVideoPopup: LitPopupElement | null;
let mainVideo: HTMLVideoElement | null | undefined;
let lens: any;
let maskTween1: gsap.core.Tween | null;
let maskTween2: gsap.core.Tween | null;

function onMainVideoPopupOpen() {
    if (mainVideo) {
        if (mainVideo.classList.contains('loaded')) {
            mainVideo.play();
        } else {
            LazyLoad.load(mainVideo, {
                callback_loaded: () => {
                    mainVideo?.play();
                },
            });
        }
    }
}

function onMainVideoPopupClose() {
    if (mainVideo) {
        mainVideo.pause();
        mainVideo.currentTime = 0;
    }
}

function setMainMargin() {
    if (footer && main) {
        main.style.marginBottom = `${footer.offsetHeight}px`;
    }
}

function removeMainMargin() {
    if (main) {
        main.style.marginBottom = '';
    }
}

// const oglPromise = fetchOgl();

export default {
    namespace: 'index-page',

    beforeEnter({ next }) {
        setMainMargin();
        window.addEventListener('resize', setMainMargin);

        document.addEventListener('common-animation-complete', () => {
            if (matchMedia('(min-width: 1025px)').matches) {
                const canvas = next.container.querySelector<HTMLCanvasElement>('canvas.js-lens-canvas');

                if (canvas) {
                    import('../components/lens/index1' /* webpackChunkName: "lens" */).then(({ createLens }) => {
                        lens = createLens(canvas);
                    });
                }
            }
        });

        enterTl = new IdleValue(() => createInitialMainPageTimeline(next.container));

        beforeEnterQueue.pushTask(() => {
            cardSliders.init(next.container);
        });

        beforeEnterQueue.pushTask(() => {
            mainVideoPopup = next.container.querySelector<LitPopupElement>(
                'app-lit-popup[data-lit-popup="main-video"]',
            );
            mainVideo = mainVideoPopup?.querySelector<HTMLVideoElement>('video.js-main-video');

            if (mainVideo) {
                mainVideo.disablePictureInPicture = true;
            }

            if (mainVideoPopup && mainVideo) {
                mainVideoPopup.instance.on('open', onMainVideoPopupOpen);
                mainVideoPopup.instance.on('close-complete', onMainVideoPopupClose);
            }
        });
    },

    afterEnter({ next }) {
        enterTl
            ?.getValue()
            .play()
            .then(() => {
                beforeEnterQueue.pushTask(() => {
                    document.dispatchEvent(new Event('main-initial-animation-complete'));
                    if (matchMedia('(min-width: 1025px)').matches) {
                        // const canvas = next.container.querySelector<HTMLCanvasElement>('canvas.js-lens-canvas');
                        // if (canvas) {
                        //     lens = createLens(canvas);
                        //     document.dispatchEvent(new Event('main-initial-animation-complete'));
                        // }
                        // document.dispatchEvent(new Event('main-initial-animation-complete'));
                    } else {
                        // document.dispatchEvent(new Event('main-initial-animation-complete'));
                        const firstBlock = next.container.querySelector<HTMLElement>('.js-first-block');
                        if (firstBlock) {
                            const blockInner = firstBlock.querySelector<HTMLElement>('.js-first-block-inner');
                            if (blockInner) {
                                const clone = blockInner.cloneNode(true) as HTMLElement;
                                clone.classList.replace(
                                    'section-first-content-inner',
                                    'section-first-content-inner-clone',
                                );
                                firstBlock.appendChild(clone);
                                maskTween1 = gsap.fromTo(
                                    clone,
                                    { '--mask-x-percent': -5 },
                                    {
                                        duration: 3 * 1.5,
                                        '--mask-x-percent': 100,
                                        yoyo: true,
                                        repeat: -1,
                                        ease: 'none',
                                    },
                                );
                                maskTween2 = gsap.fromTo(
                                    clone,
                                    { '--mask-y-percent': 20 },
                                    {
                                        duration: 5 * 1.5,
                                        '--mask-y-percent': 35,
                                        yoyo: true,
                                        repeat: -1,
                                        ease: 'none',
                                    },
                                );
                            }
                        }
                    }
                });
            });
    },

    beforeLeave() {
        beforeEnterQueue.clearPendingTasks();

        if (enterTl) {
            enterTl.getValue().kill();
            enterTl = null;
        }

        if (maskTween1) {
            maskTween1.kill();
            maskTween1 = null;
        }

        if (maskTween2) {
            maskTween2.kill();
            maskTween2 = null;
        }

        if (mainVideoPopup) {
            mainVideoPopup.instance.off('open', onMainVideoPopupOpen);
            mainVideoPopup.instance.off('close-complete', onMainVideoPopupClose);
        }
    },

    afterLeave({ current }) {
        cardSliders.destroy(current.container);

        if (lens) {
            lens.destroy();
            lens = null;
        }

        window.removeEventListener('resize', setMainMargin);
        removeMainMargin();
    },
} as IView;
