import noLoopSliders from '../components/no-loop-sliders';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-no-loop-slider')).forEach((el) => {
        noLoopSliders.init(el, { 
            spaceBetween: 5,
            centeredSlides: false,
        });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-no-loop-slider')).forEach((el) => {
        noLoopSliders.destroy(el);
    });
}

const _module = { init, destroy };

export default _module;
