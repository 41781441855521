import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ease } from '../ease';

export default function splittedText(el: HTMLElement, speedKoef = 1) {
    el.classList.add('splittable');
    const splitted = new SplitText(el, { type: 'chars,words,lines' });
    const { chars } = splitted;

    const tl = gsap.timeline({
        defaults: { duration: 1 * speedKoef, ease },
        paused: true,
        onStart: () => {
            el.classList.add('splittable--initialized');
        },
    });

    tl.fromTo(
        chars,
        { opacity: 0, yPercent: 100 },
        { yPercent: 0, opacity: 1, duration: 0.3 * speedKoef, stagger: 0.07 * speedKoef },
    );

    return tl;
}
