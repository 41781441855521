import gsap from 'gsap';

export function createInitialBaseTimeline() {
    const headerEls = Array.from(document.querySelectorAll('.js-initial-animation__header-el'));
    const tl = gsap.timeline({ paused: true, defaults: { duration: 1, ease: 'power3.out' } });

    tl.fromTo(
        headerEls,
        { opacity: 0, y: 20 },
        {
            opacity: 1,
            y: 0,
            stagger: 0.07,
            onComplete: () => {
                headerEls.forEach((el) => {
                    el.classList.add('is-animated');
                });
            },
        },
    ).add(() => {
        document.body.classList.add('app-ready');
    }, 0.3);

    return tl;
}
