import type { Swiper as SwiperType, SwiperOptions } from 'swiper';
import deepMerge from 'lodash.merge';
import { fetchSwiper } from '../dynamic-modules';
// import { executeOnIntersection } from '../utils/execute-on-intersection';

export const map = new Map<Element, any>();

function generateNavButton(direction: 'prev' | 'next') {
    const button = document.createElement('button');
    button.className = `ripple btn-arrow btn-arrow--${direction} js-slider-${direction}`;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    return button;
}

export function createNav(swiper: SwiperType, sliderNav?: Element | null) {
    if (sliderNav) {
        const navigateBackwards = () => {
            swiper.slidePrev();
        };

        const navigateForwards = () => {
            swiper.slideNext();
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev');
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next');
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        sliderNav.appendChild(fragment);
    }
}

export function destroyNav(containerEl: Element) {
    const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
    const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');

    [prevButton, nextButton].forEach((btn) => {
        if (btn) {
            const listener = map.get(btn);

            if (listener) {
                btn.removeEventListener('click', listener);
                map.delete(btn);
            }
        }
    });
}

async function init(el: HTMLElement, sliderOptions: SwiperOptions = {}) {
    const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

    if (slides.length > 1) {
        const { Swiper, Lazy, Autoplay, Pagination, FreeMode } = await fetchSwiper();
        const sliderBlock = el.closest<HTMLElement>('.js-slider-block');
        const sliderNav = sliderBlock?.querySelector<HTMLElement>('.js-slider-nav');

        // executeOnIntersection(
        //     el,
        //     () => {
        const slider = new Swiper(
            el,
            deepMerge<SwiperOptions, SwiperOptions>(
                {
                    modules: [Lazy, Autoplay, Pagination, FreeMode],
                    slidesPerView: 'auto',
                    loop: false,
                    centeredSlides: true,
                    speed: 800,
                    watchSlidesProgress: true,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 2,
                        checkInView: true,
                    },
                    on: {
                        beforeInit: (swiper) => {
                            createNav(swiper, sliderNav);
                        },
                        init: (swiper) => {
                            sliderBlock?.classList.add('slider-block-initialized');

                            if (
                                typeof swiper.params.slidesPerView === 'number' &&
                                slides.length <= swiper.params.slidesPerView
                            ) {
                                swiper.disable();
                            }
                        },
                        destroy: () => {
                            destroyNav(el);
                            sliderBlock?.classList.remove('slider-block-initialized');
                        },
                        resize: (swiper) => {
                            if (
                                typeof swiper.params.slidesPerView === 'number' &&
                                slides.length <= swiper.params.slidesPerView
                            ) {
                                swiper.disable();
                            } else {
                                swiper.enable();
                            }
                        },
                    },
                },
                sliderOptions,
            ),
        );
        map.set(el, slider);
        //     },
        //     { rootMargin: '200px 0px 200px 0px' },
        // );
    }
}

function destroy(el: HTMLElement) {
    const slider = map.get(el);

    if (slider) {
        slider.destroy();
        map.delete(el);
    }
}

const _module = { init, destroy };

export default _module;
