import barba from '@barba/core';
import { IdleQueue } from 'idlize/IdleQueue.mjs';
import { IdleValue } from 'idlize/IdleValue.mjs';
import { lazyload } from '../inits/lazy-load';
import ajaxForms from '../inits/ajax-forms';
import revealAnimations from '../inits/reveal';
import stickySections from '../inits/sticky-sections';
import videoPlayingState from '../inits/video-playing-state';
import defaultSliders from '../inits/default-sliders';
import noLoopSliders from '../inits/no-loop-sliders-init';
import storySliders from '../inits/story-sliders';
import verticalCardSliders from '../inits/vertical-card-sliders';
import galleries from '../inits/gallery';
import selects from '../inits/select';
import { executeOnIntersection } from '../utils/execute-on-intersection';
import splittedText from '../animations/splitted-text';
import { createInitialCommonTimeline } from '../animations/initial-common';
import { wrap } from '../utils/dom';
import { isMobileDevice } from '../utils/mobile';

function attemptToInitSelects() {
    if (isMobileDevice()) {
        selects.destroy();
    } else {
        selects.init();
    }
}

export function initBaseView() {
    const beforeEnterQueue = new IdleQueue();
    let baseEnterTl: gsap.core.Timeline | null;

    window.addEventListener('resize', attemptToInitSelects);

    barba.hooks.beforeEnter((data) => {
        attemptToInitSelects();

        if (data) {
            // Адаптивные таблицы в wysiwyg
            Array.from(data.next.container.querySelectorAll('.wysiwyg table')).forEach((el) => {
                const wrapper = document.createElement('div');
                wrapper.className = 'table-responsive';
                wrap(el, wrapper);
            });
        }

        baseEnterTl = new IdleValue(() => createInitialCommonTimeline(data?.next.container));

        beforeEnterQueue.pushTask(() => {
            stickySections.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            defaultSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            noLoopSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            storySliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            verticalCardSliders.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            videoPlayingState.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            ajaxForms.init(data?.next.container);
        });

        beforeEnterQueue.pushTask(() => {
            galleries.init(data?.next.container);
        });
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.body.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
    });

    barba.hooks.afterEnter((data) => {
        revealAnimations.init(data?.next.container);
        if (data) {
            Array.from(data.next.container.querySelectorAll<HTMLElement>('.js-reveal-splitted')).forEach((el) => {
                const tl = new IdleValue(() => splittedText(el));
                beforeEnterQueue.pushTask(() => {
                    executeOnIntersection(el, () => tl.getValue().play(), { threshold: 0.5 });
                });
            });
        }

        beforeEnterQueue.pushTask(() => {
            baseEnterTl?.getValue().play();
        });
    });

    barba.hooks.beforeLeave((data) => {
        beforeEnterQueue.clearPendingTasks();
        videoPlayingState.destroy(data?.current.container);
        ajaxForms.destroy(data?.current.container);
        revealAnimations.destroy(data?.current.container);
        selects.destroy(data?.current.container);
        stickySections.destroy(data?.current.container);
        galleries.destroy(data?.current.container);
    });

    barba.hooks.afterLeave((data) => {
        defaultSliders.destroy(data?.current.container);
        noLoopSliders.destroy(data?.next.container);
        storySliders.destroy(data?.current.container);
        verticalCardSliders.destroy(data?.current.container);
        document.body.classList.remove('no-scroll', 'menu--opened');
    });
}
