import baseSliders from '../components/base-sliders';

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-default-slider')).forEach((el) => {
        baseSliders.init(el, { spaceBetween: 5 });
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-default-slider')).forEach((el) => {
        baseSliders.destroy(el);
    });
}

const _module = { init, destroy };

export default _module;
