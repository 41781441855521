import barba from '@barba/core';
import { appQueue } from './app-queue';

let ymFirstHit = false;

const hit = () => {
    (window as any).ym?.(88187058, 'hit', window.location.pathname);
};

appQueue.pushTask(() => {
    if (NODE_ENV === 'production') {
        if (!(window as any).ym) {
            (function (m, e, t, r, i, k, a) {
                m[i] =
                    m[i] ||
                    function () {
                        (m[i].a = m[i].a || []).push(arguments);
                    };
                m[i].l = 1 * new Date();
                (k = e.createElement(t)),
                    (a = e.getElementsByTagName(t)[0]),
                    (k.async = 1),
                    (k.src = r),
                    a.parentNode.insertBefore(k, a);
            })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

            (window as any).ym?.(88187058, 'init', {
                defer: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });

            // на случай, если коллбэк сработал позже барбы
            if (!ymFirstHit) {
                hit();
            }
        }
    }
});

barba.hooks.afterEnter(() => hit());
