import { Stack } from '../components/stack';
import debounce from 'lodash.debounce';

const map = new WeakMap<HTMLElement, Stack>();

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-stacks:not(.stacks--initialized)')).forEach((el) => {
        const onResize = () => {
            const stackInstance = map.get(el);

            if (matchMedia('(max-width: 815px) and (orientation: landscape)').matches) {
                if (stackInstance) {
                    stackInstance.destroy();
                    map.delete(el);
                }
            } else if (!stackInstance) {
                const stacksBlock = el.querySelector<HTMLElement>('.js-stacks-block');

                if (stacksBlock) {
                    const stack = new Stack(stacksBlock);
                    map.set(el, stack);
                }

                // устаналиваем паддинг для блока, чтоб не было пустого пространства
                if (matchMedia('(max-width: 767px)').matches) {
                    const stacks = Array.from(el.querySelectorAll<HTMLElement>('.js-stack'));
                    const stacksSection = el.closest<HTMLElement>('.js-sticky-blurry-section');

                    let maxHeight = 0;
                    stacks.forEach((stack) => {
                        const height = stack.offsetHeight;
                        if (height > maxHeight) {
                            maxHeight = height;
                        }
                    });

                    const heightBlock = maxHeight + (stacks.length - 1) * 30 + 40;
                    stacksSection?.style.setProperty('--padding-bottom', `${heightBlock}px`);
                }
            }
        };
        const debouncedOnResize = debounce(onResize, 100);

        onResize();
        (el as any)._stackOnResize = debouncedOnResize;
        window.addEventListener('resize', debouncedOnResize);
        el.classList.add('stacks--initialized');
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-stacks')).forEach((el) => {
        const stackInstance = map.get(el);

        if (typeof (el as any)._stackOnResize === 'function') {
            window.removeEventListener('resize', (el as any)._stackOnResize);
            (el as any)._stackOnResize = null;
        }

        if (stackInstance) {
            stackInstance.destroy();
            map.delete(el);
        }

        el.classList.remove('stacks--initialized');
    });
}

function getInstanceByElement(el?: HTMLElement | null) {
    return el ? map.get(el) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
