import type { Swiper as SwiperType } from 'swiper';
import { fetchSwiper } from '../dynamic-modules';
import { createNav, destroyNav, map } from '../components/base-sliders';
// import { executeOnIntersection } from '../utils/execute-on-intersection';
import { viewport } from '../viewport';

function getOffset(el: HTMLElement) {
    if (matchMedia('(max-width: 767px)').matches) {
        return 10;
    }

    if (matchMedia('(max-width: 1024px)').matches) {
        return 26;
    }

    const columnWidth = viewport.width / 48;

    if (matchMedia('(max-width: 1366px)').matches) {
        return columnWidth * 1.5;
    }

    return columnWidth * 2;
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-vertical-card-slider')).forEach(async (el) => {
        const slides = Array.from(el.querySelectorAll<HTMLElement>('.swiper-slide'));

        if (slides.length > 1) {
            const { Swiper, Lazy } = await fetchSwiper();
            const sliderBlock = el.closest<HTMLElement>('.js-slider-block');
            const sliderNav = sliderBlock?.querySelector<HTMLElement>('.js-slider-nav');
            let hold = false;
            let isTransitionSet = false;
            let timer: NodeJS.Timeout;
            let offset = getOffset(slides[0].children[0] as HTMLElement);
            const GRID_COLUMN_WIDTH = viewport.width / 48;

            // executeOnIntersection(
            //     el,
            //     () => {
            const onMousemove = () => {
                if (matchMedia('(max-width: 767px)').matches || !hold || isTransitionSet) return;

                timer = setTimeout(() => {
                    // eslint-disable-next-line no-use-before-define
                    slider.el.style.setProperty('--transition-duration-active', '0s');
                }, 150);

                isTransitionSet = true;
            };

            const onMouseup = () => {
                if (matchMedia('(max-width: 767px)').matches) return;
                clearTimeout(timer);
                hold = false;
                isTransitionSet = false;
                // eslint-disable-next-line no-use-before-define
                slider.el.style.removeProperty('--transition-duration-active');
                document.removeEventListener('mousemove', onMousemove);
                document.removeEventListener('mouseup', onMouseup);
            };

            const onMousedown = () => {
                if (matchMedia('(max-width: 767px)').matches) return;
                hold = true;
                document.addEventListener('mousemove', onMousemove);
                document.addEventListener('mouseup', onMouseup);
            };

            const setTranslate = (swiper: SwiperType, wrapperTranslate: number) => {
                if (matchMedia('(max-width: 767px)').matches) return;
                const { size } = swiper as any;

                slides
                    .find((_el) => _el.classList.contains('swiper-slide-transitioning'))
                    ?.classList.remove('swiper-slide-transitioning');

                if (swiper.realIndex < swiper.previousIndex) {
                    slides[swiper.previousIndex].classList.add('swiper-slide-transitioning');
                }

                for (let i = 0; i < slides.length - 1; i++) {
                    const slide = slides[i + 1] as HTMLElement;
                    const mult = size / 2 + (size - offset) * i + GRID_COLUMN_WIDTH;

                    slide.style.transform = `translate3d(${
                        Math.max(
                            -(slide as any).swiperSlideOffset + size + (i + 1) * offset,
                            -mult + (i < swiper.realIndex ? wrapperTranslate : 0) + size,
                        ) - size
                    }px, 0, 0)`;
                }
            };

            const slider = new Swiper(el, {
                modules: [Lazy],
                slidesPerView: 'auto',
                preloadImages: false,
                lazy: {
                    loadPrevNext: true,
                    checkInView: true,
                },
                on: {
                    beforeInit: (swiper) => {
                        createNav(swiper, sliderNav);
                    },
                    init: (swiper) => {
                        swiper.el.addEventListener('mousedown', onMousedown);
                        setTranslate(swiper, 0);
                        sliderBlock?.classList.add('slider-block-initialized');
                    },
                    resize: () => {
                        offset = getOffset(slides[0].children[0] as HTMLElement);
                    },
                    destroy: (swiper) => {
                        destroyNav(el);
                        swiper.el.removeEventListener('mousedown', onMousedown);
                        document.removeEventListener('mousemove', onMousemove);
                        document.removeEventListener('mouseup', onMouseup);
                        clearTimeout(timer);
                        hold = false;
                        isTransitionSet = false;
                        sliderBlock?.classList.remove('slider-block-initialized');
                    },
                    setTranslate: (swiper, translate) => {
                        requestAnimationFrame(() => {
                            setTranslate(swiper, translate);
                        });
                    },
                },
                breakpoints: {
                    768: {
                        virtualTranslate: true,
                        watchSlidesProgress: true,
                        speed: 0,
                        slidesPerView: 1,
                    },
                },
            });
            map.set(el, slider);
            //     },
            //     { rootMargin: '200px 0px 200px 0px' },
            // );
        }
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-vertical-card-slider')).forEach((el) => {
        const slider = map.get(el);

        if (slider) {
            slider.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
