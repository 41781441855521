export const fetchSwiper = () => import(/* webpackChunkName: "swiper" */ 'swiper');

export const fetchContentList = () =>
    import(/* webpackChunkName: "content-list" */ '@chipsadesign/content-list').then((m) => m.default);

export const fetchContentListFilter = () =>
    import(
        /* webpackChunkName: "content-list-filter" */ '@chipsadesign/content-list/dist/modules/ContentListFilter'
    ).then((m) => m.default);

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchOgl = () =>
    import(
        'ogl'
        /* webpackChunkName: "ogl" */
    );

export const fetchImask = () =>
    Promise.all([
        import(
            'imask/esm/imask'
            /* webpackChunkName: "imask" */
        ),
        import(
            'imask/esm/masked/number'
            /* webpackChunkName: "imask-number" */
        ),
    ]).then(([m]) => m);

export const fetchLightgallery = () =>
    Promise.all([
        import(
            'lightgallery'
            /* webpackChunkName: "lightgallery" */
        ),
        import(
            'lightgallery/css/lightgallery.css'
            /* webpackChunkName: "lightgallery-styles" */
        ),
        import(
            '../css/layout/_lightgallery.scss'
            /* webpackChunkName: "lightgallery-app-styles" */
        ),
    ]).then(([m]) => m);
