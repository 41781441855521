import delegate from 'delegate';
import { fetchLightgallery } from '../dynamic-modules';

const lgMap = new WeakMap<HTMLElement, any>();
const delegationMap = new WeakMap<HTMLElement, any>();

function onBeforeGalleryOpen() {
    document.body.classList.add('no-scroll', 'menu--opened', 'webinars-popup-opened');
}

function onBeforeGalleryClose() {
    document.body.classList.remove('no-scroll', 'menu--opened', 'webinars-popup-opened');
}

async function openGallery(event: any) {
    event.preventDefault();
    const target = event.delegateTarget as HTMLElement;
    const parent = target.closest<HTMLElement>('.js-gallery');

    if (parent) {
        try {
            const { default: lightGallery } = await fetchLightgallery();

            if (!lgMap.has(parent)) {
                const gallery = lightGallery(parent, {
                    animateThumb: false,
                    zoomFromOrigin: false,
                    allowMediaOverlap: true,
                    toggleThumb: true,
                    download: false,
                    licenseKey: '1111-1111-111-1111',
                    mobileSettings: {
                        showCloseIcon: true,
                        controls: true,
                    },
                });
                parent.addEventListener('lgBeforeOpen', onBeforeGalleryOpen);
                parent.addEventListener('lgBeforeClose', onBeforeGalleryClose);
                gallery.openGallery(Array.from(parent.querySelectorAll('.js-gallery-button')).indexOf(target));
                lgMap.set(parent, gallery);
            }
        } catch (err: any) {
            if (target instanceof HTMLAnchorElement) {
                window.open(target.href, '_blank', 'noopener')?.focus();
            }

            throw err;
        }
    }
}

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-gallery'));
    elements.forEach((el) => {
        const delegation = delegate(el, '.js-gallery-button', 'click', openGallery);
        delegationMap.set(el, delegation);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-gallery'));
    elements.forEach((el) => {
        const delegation = delegationMap.get(el);
        const gallery = lgMap.get(el);

        el.removeEventListener('lgBeforeOpen', onBeforeGalleryOpen);
        el.removeEventListener('lgBeforeClose', onBeforeGalleryClose);

        if (delegation) {
            delegation.destroy();
            delegationMap.delete(el);
        }

        if (gallery) {
            gallery.destroy();
            lgMap.delete(el);
        }
    });
}

const _module = { init, destroy };

export default _module;
