import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import debounce from 'lodash.debounce';
import stacks from './stacks';
import type { Stack } from '../components/stack';

const resizeMap = new WeakMap<HTMLElement | Document, () => void>();
const blurMap = new WeakMap<HTMLElement, gsap.core.Tween>();
const stickyMap = new WeakMap<HTMLElement, gsap.core.Tween>();
let resizeObserver: ResizeObserver | null;

function init(container: HTMLElement | Document = document) {
    gsap.registerPlugin(ScrollTrigger);
    const sections = Array.from(container.querySelectorAll<HTMLElement>('.js-sticky-blurry-section'));

    const onResize = () => {
        if (matchMedia('(min-width: 1025px)').matches) {
            let isWebGLWorking = true;

            stacks.init(container);

            const stackElements = Array.from(container.querySelectorAll<HTMLElement>('.js-stack'));
            const stacksSection = container.querySelector<HTMLElement>('.stacks-section');
            const stackElementsHeight = stackElements.reduce((acc, element) => acc + element.offsetHeight, 0);

            if (stacksSection?.nextElementSibling) {
                (stacksSection.nextElementSibling as HTMLElement).style.setProperty(
                    '--stacks-height',
                    `${stackElementsHeight}px`,
                );
            }

            sections.forEach((el) => {
                if (!el.classList.contains('stacks-section')) {
                    el.style.setProperty('--subsection-height', `${el.offsetHeight}px`);
                }
            });

            //

            sections.forEach((el, i) => {
                const stickyTween = stickyMap.get(el);
                if (!stickyTween) {
                    // const useBlur = el.dataset.useBlur === 'true';
                    let stackTween: Stack | undefined;
                    const isStacksSection = el.classList.contains('stacks-section');
                    // let _stickProgress = 0;
                    // let rAF = 0;
                    // const blur = () => {
                    //     if (el !== sections[sections.length - 1] && !isStacksSection) {
                    //         if (
                    //             // matchMedia('(max-width: 767px), (max-width: 815px) and (orientation: landscape)')
                    //             //     .matches
                    //             matchMedia('(max-width: 1024px)').matches
                    //         ) {
                    //             el.style.filter = 'none';
                    //         } else if (!el.classList.contains('sticky-blurry-hidden')) {
                    //             el.style.filter = `blur(${8 * _stickProgress}px)`;
                    //         }
                    //     }
                    // };
                    // const scheduleBlurUpdate = () => {
                    //     cancelAnimationFrame(rAF);
                    //     rAF = requestAnimationFrame(blur);
                    // };
                    if (isStacksSection) {
                        stackTween = stacks.getInstanceByElement(el.querySelector<HTMLElement>('.js-stacks'));
                    }
                    const tween = gsap.to(el, {
                        scrollTrigger: {
                            trigger: el,
                            // pin: el !== sections[sections.length - 1],
                            // anticipatePin: 1,
                            // pinSpacing: isStacksSection,
                            start: 'bottom bottom',
                            end:
                                i === sections.length - 1
                                    ? 'top bottom'
                                    : // : () => stackTween?.tween.scrollTrigger?.end || '+=100%',
                                      () =>
                                          stackTween?.tween.scrollTrigger?.end
                                              ? stackTween.tween.scrollTrigger.end +
                                                (el.classList.contains('goods-section') ? 0 : 0)
                                              : `+=${
                                                    window.innerHeight +
                                                    (el.classList.contains('goods-section') ? 0 : 0)
                                                }`,
                            onLeave: () => {
                                // cancelAnimationFrame(rAF);
                                if (!isStacksSection && i < sections.length - 1) {
                                    el.classList.add('sticky-blurry-hidden');
                                    el.dispatchEvent(new Event('sticky-hide'));
                                    if (el.classList.contains('js-lens-canvas-container')) {
                                        if (isWebGLWorking) {
                                            document.dispatchEvent(new Event('webgl-loop-stop'));
                                            isWebGLWorking = false;
                                        }
                                    }
                                }
                            },
                            onLeaveBack: () => {
                                el.classList.remove('enter-back');
                            },
                            onEnter: () => {
                                el.classList.remove('sticky-blurry-hidden');
                                el.dispatchEvent(new Event('sticky-show'));
                                el.classList.add('enter-back');
                                if (el.classList.contains('js-lens-canvas-container')) {
                                    if (!isWebGLWorking) {
                                        document.dispatchEvent(new Event('webgl-loop-start'));
                                        isWebGLWorking = true;
                                    }
                                }
                            },
                            onEnterBack: () => {
                                el.classList.remove('sticky-blurry-hidden');
                                el.dispatchEvent(new Event('sticky-show'));
                                el.classList.add('enter-back');
                                if (el.classList.contains('js-lens-canvas-container') && !isWebGLWorking) {
                                    document.dispatchEvent(new Event('webgl-loop-start'));
                                    isWebGLWorking = true;
                                }
                            },
                            // onUpdate: ({ progress }) => {
                            //     if (useBlur) {
                            //         _stickProgress = progress;
                            //         scheduleBlurUpdate();
                            //     }
                            // },
                            // onRefresh: () => {
                            //     console.log('refresh');
                            //     el.style.contentVisibility = 'visible';
                            //     setTimeout(() => {
                            //         el.style.contentVisibility = '';
                            //     }, 1);
                            // },
                        },
                    });
                    stickyMap.set(el, tween);
                }
            });
        } else {
            // sections.forEach((el) => {
            //     const stickyTween = stickyMap.get(el);

            //     if (stickyTween) {
            //         stickyTween.scrollTrigger?.kill();
            //         stickyTween.kill();
            //         stickyMap.delete(el);
            //     } else {
            //         el.classList.remove('sticky-blurry-hidden');
            //         el.dispatchEvent(new Event('sticky-show'));
            //     }
            // });

            stacks.init(container);
        }
    };

    const debouncedOnResize = debounce(onResize, 100);

    onResize();

    if (window.ResizeObserver) {
        resizeObserver = new ResizeObserver(() => {
            onResize();
        });

        sections.forEach((section) => {
            resizeObserver!.observe(section);
        });
    } else {
        window.addEventListener('resize', debouncedOnResize);
    }

    resizeMap.set(container, debouncedOnResize);
}

function destroy(container: HTMLElement | Document = document) {
    const sections = Array.from(container.querySelectorAll<HTMLElement>('.js-sticky-blurry-section'));
    const resizeFn = resizeMap.get(container);

    sections.forEach((el) => {
        const blurTween = blurMap.get(el);
        const stickyTween = stickyMap.get(el);

        if (blurTween) {
            blurTween.kill();
            blurMap.delete(el);
        }

        if (stickyTween) {
            stickyTween.kill();
            stickyMap.delete(el);
        }
    });

    if (resizeFn) {
        window.removeEventListener('resize', resizeFn);
        resizeMap.delete(container);
    }

    if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
    }
}

const _module = { init, destroy };

export default _module;
