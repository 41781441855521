import delegate from 'delegate';
import ripplesWorklet from '../worklets/ripples.worklet';

function onClick(event: any) {
    const button = event.delegateTarget as HTMLElement;
    const isButtonFocused = button.classList.contains('focus-visible');
    const rect = isButtonFocused ? button.getBoundingClientRect() : null;
    const x = rect ? rect.width / 2 : event.offsetX;
    const y = rect ? rect.height / 2 : event.offsetY;
    const start = performance.now();

    button.style.setProperty('--ripple-x', x);
    button.style.setProperty('--ripple-y', y);

    requestAnimationFrame(function raf(now) {
        const count = Math.floor(now - start);
        button.style.setProperty('--ripple-animation-tick', `${count}`);

        if (count > 1000) {
            button.style.setProperty('--ripple-animation-tick', '0');
            return;
        }

        requestAnimationFrame(raf);
    });
}

export function initWorklets() {
    if ('paintWorklet' in CSS) {
        CSS.paintWorklet.addModule(ripplesWorklet);
        delegate(document, '.ripple', 'click', onClick);
    }
}
