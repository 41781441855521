import './WebShare.scss';
import { LitElement, html, css, unsafeCSS } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { ok, mail, viber, whatsapp, telegram } from 'vanilla-sharing';
import _styles from './WebShare.ce.scss';
import { isMobileDevice } from '../../utils/mobile';
import loadScript from '../../../modules/load-script';

export interface WebShare {
    facebook: boolean;
    facebookAppId: string | null;
    facebookReady: boolean;
    mail: boolean;
    native: boolean;
    ok: boolean;
    telegram: boolean;
    text: string;
    title: string;
    twitter: boolean;
    skype: boolean;
    skypeReady: boolean;
    url: string;
    viber: boolean;
    vk: boolean;
    vkButtonMarkup: string;
    vkReady: boolean;
    whatsapp: boolean;
    _useNative: boolean;
}

function getWindowOptions() {
    const width = 500;
    const height = 350;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    return ['resizable, scrollbars, status', `height=${height}`, `width=${width}`, `left=${left}`, `top=${top}`].join();
}

export function setVkBtnMarkup(options = {}, markup = '') {
    return (window as any).VK ? (window as any).VK.Share.button(options, { type: 'custom', text: markup }) : '';
}

export function shareFacebook(url: string) {
    if ((window as any).FB) {
        (window as any).FB.ui({
            method: 'share',
            href: url,
        });
    }
}

export function shareTwitter(url: string, text = '') {
    const win = window.open(
        `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
        'Поделиться в Twitter',
        getWindowOptions(),
    );

    if (win) {
        win.opener = null;
    }
}

export function shareOK(url: string) {
    ok({
        url,
        title: document.title,
    });
}

export function shareMail(url: string) {
    mail({
        url,
        title: document.title,
    });
}

export function shareViber(url: string) {
    viber({
        url,
        title: document.title,
    });
}

export function shareWhatsapp(url: string) {
    whatsapp({
        url,
        title: document.title,
    });
}

export function shareTelegram(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

export function shareSkype(url: string) {
    telegram({
        url,
        title: document.title,
    });
}

export async function loadVKSharingSDK() {
    await loadScript('https://vk.com/js/api/share.js?95');
}

export function loadSkypeSharingSDK(): Promise<void> {
    return new Promise((resolve) => {
        ((r, d, s) => {
            r.loadSkypeWebSdkAsync =
                r.loadSkypeWebSdkAsync ||
                function (p) {
                    const sjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(p.id)) {
                        return;
                    }
                    const js = d.createElement(s);
                    js.id = p.id;
                    js.src = p.scriptToLoad;
                    js.onload = p.callback;
                    sjs.parentNode.insertBefore(js, sjs);
                };
            const p = {
                scriptToLoad: 'https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js',
                id: 'skype_web_sdk',
                callback: resolve,
            };
            r.loadSkypeWebSdkAsync(p);
        })(window, document, 'script');
    });
}

export async function loadFacebookSharingSDK(fbAppId?: string | null) {
    if (!fbAppId) {
        console.error('Invalid Facebook App ID');
    }

    await loadScript(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0&appId=${fbAppId}`);
}

export class WebShare extends LitElement {
    constructor() {
        super();
        this.share = this.share.bind(this);
        this._closeOnOutsideClick = this._closeOnOutsideClick.bind(this);

        this.title = document.title;
        this.text = '';
        this.url = window.location.href;

        this.vkButtonMarkup = '';
        this.vkReady = false;
        this.facebookReady = false;
        this.skypeReady = false;

        this.facebook = false;
        this.mail = false;
        this.native = false;
        this._useNative = false;
        this.ok = false;
        this.telegram = false;
        this.twitter = false;
        this.skype = false;
        this.viber = false;
        this.vk = false;
        this.whatsapp = false;

        this.facebookAppId = this.getAttribute('facebook-app-id');
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    static get properties() {
        return {
            facebook: {
                type: Boolean,
            },
            facebookAppId: {
                type: String,
            },
            mail: {
                type: Boolean,
            },
            native: {
                type: Boolean,
            },
            ok: {
                type: Boolean,
            },
            telegram: {
                type: Boolean,
            },
            twitter: {
                type: Boolean,
            },
            skype: {
                type: Boolean,
            },
            viber: {
                type: Boolean,
            },
            vk: {
                type: Boolean,
            },
            vkButtonMarkup: {
                type: String,
            },
            whatsapp: {
                type: Boolean,
            },
            _useNative: {
                type: Boolean,
            },
        };
    }

    connectedCallback() {
        super.connectedCallback();
        this._useNative = this.native && 'share' in navigator && isMobileDevice();

        if (!this._useNative) {
            if (this.facebook) {
                loadFacebookSharingSDK(this.facebookAppId).then(() => {
                    this.facebookReady = true;
                });
            }

            if (this.skype) {
                loadSkypeSharingSDK().then(() => {
                    this.skypeReady = true;
                });
            }

            if (this.vk) {
                loadVKSharingSDK().then(() => {
                    this.vkReady = true;
                    this.vkButtonMarkup = setVkBtnMarkup(
                        { title: this.title, url: this.url },
                        `   
                            <span class="ripple btn btn-default btn-sm">
                                <span class="btn-sm__inner">
                                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0789 13.0683C12.0789 13.0683 12.476 13.025 12.6793 12.8106C12.8655 12.6141 12.859 12.2434 12.859 12.2434C12.859 12.2434 12.8343 10.5123 13.6531 10.2567C14.4601 10.0053 15.4964 11.9308 16.596 12.6712C17.4267 13.231 18.0573 13.1084 18.0573 13.1084L20.9959 13.0683C20.9959 13.0683 22.5325 12.9754 21.804 11.7892C21.7437 11.6921 21.379 10.9115 19.6197 9.30817C17.7764 7.62984 18.0239 7.90129 20.2427 4.99775C21.5942 3.22964 22.1343 2.15018 21.9654 1.68861C21.8051 1.24712 20.8108 1.36436 20.8108 1.36436L17.5031 1.38442C17.5031 1.38442 17.2578 1.35168 17.076 1.45836C16.8984 1.56292 16.7833 1.80691 16.7833 1.80691C16.7833 1.80691 16.2603 3.17471 15.562 4.33867C14.0889 6.79332 13.5003 6.92323 13.2593 6.77114C12.6987 6.41519 12.8386 5.34313 12.8386 4.5816C12.8386 2.20194 13.2066 1.21015 12.123 0.953487C11.7636 0.867933 11.4989 0.811953 10.5789 0.802447C9.39855 0.790829 8.4 0.806672 7.83401 1.07812C7.4574 1.25873 7.16688 1.66221 7.34442 1.68545C7.56285 1.71396 8.05782 1.81642 8.32037 2.16708C8.65932 2.6202 8.64748 3.63628 8.64748 3.63628C8.64748 3.63628 8.84224 6.43737 8.19233 6.78487C7.74685 7.02357 7.13567 6.53666 5.82185 4.30909C5.14934 3.16838 4.64146 1.90725 4.64146 1.90725C4.64146 1.90725 4.54354 1.67172 4.36815 1.54497C4.15617 1.39182 3.86027 1.34429 3.86027 1.34429L0.717209 1.36436C0.717209 1.36436 0.244836 1.37703 0.0715967 1.57877C-0.0822743 1.75727 0.0597604 2.128 0.0597604 2.128C0.0597604 2.128 2.52062 7.77983 5.30751 10.6284C7.86306 13.2394 10.764 13.0683 10.764 13.0683H12.0789Z" fill="white"/>
                                    </svg>
                                </span>
                            </span>
                        `,
                    );
                });
            }
        }

        document.addEventListener('click', this._closeOnOutsideClick);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('click', this._closeOnOutsideClick);
    }

    async share() {
        if (this._useNative) {
            await navigator.share({ text: this.text, title: this.title, url: this.url });
        } else {
            this.renderRoot.querySelector('.list')?.classList.toggle('is-open');
        }
    }

    _closeOnOutsideClick(event: any) {
        const el = event.target.closest('app-web-share');

        if (!el) {
            this.renderRoot.querySelector('.list')?.classList.remove('is-open');
        }
    }

    renderList() {
        return html`
            <ul class="list">
                ${this.facebook && this.facebookReady && this.facebookAppId
                    ? html`
                          <li class="list__item">
                              <button
                                  class="ripple btn btn-default btn-sm"
                                  aria-label="Поделиться в Facebook"
                                  @click="${() => shareFacebook(this.url)}"
                              >
                                  <span class="btn-sm__inner">
                                      <svg
                                          height="24"
                                          viewBox="0 0 24 24"
                                          width="24"
                                          xmlns="http://www.w3.org/2000/svg"
                                      >
                                          <path
                                              fill="#fff"
                                              d="M15.997 3.985h2.191V.169C17.81.117 16.51 0 14.996 0c-3.159 0-5.323 1.987-5.323 5.639V9H6.187v4.266h3.486V24h4.274V13.267h3.345l.531-4.266h-3.877V6.062c.001-1.233.333-2.077 2.051-2.077z"
                                          />
                                      </svg>
                                  </span>
                              </button>
                          </li>
                      `
                    : ''}
                ${this.vk && this.vkReady ? html`<li class="list__item">${unsafeHTML(this.vkButtonMarkup)}</li>` : ''}
                ${this.twitter
                    ? html` <li class="list__item">
                          <button
                              class="ripple btn btn-default btn-sm"
                              aria-label="Поделиться в Twitter"
                              @click="${() => shareTwitter(this.url, this.text)}"
                          >
                              <span class="btn-sm__inner">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24">
                                      <path
                                          fill="#fff"
                                          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
                                      />
                                  </svg>
                              </span>
                          </button>
                      </li>`
                    : ''}
                ${this.ok
                    ? html` <li class="list__item">
                          <button
                              class="ripple btn btn-default btn-sm"
                              aria-label="Поделиться в Odnoklassniki"
                              @click="${() => shareOK(this.url)}"
                          >
                              <span class="btn-sm__inner">
                                  <svg height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M4.721 12.881c-.613 1.205.083 1.781 1.671 2.765 1.35.834 3.215 1.139 4.413 1.261l-4.721 4.541c-1.374 1.317.838 3.43 2.211 2.141l3.717-3.585c1.423 1.369 2.787 2.681 3.717 3.59 1.374 1.294 3.585-.801 2.226-2.141-.102-.097-5.037-4.831-4.736-4.541 1.213-.122 3.05-.445 4.384-1.261l-.001-.001c1.588-.989 2.284-1.564 1.68-2.769-.365-.684-1.349-1.256-2.659-.267 0 0-1.769 1.355-4.622 1.355-2.854 0-4.622-1.355-4.622-1.355-1.309-.994-2.297-.417-2.658.267z"
                                      />
                                      <path
                                          d="M11.999 12.142c3.478 0 6.318-2.718 6.318-6.064 0-3.36-2.84-6.078-6.318-6.078C8.52 0 5.68 2.718 5.68 6.078c0 3.346 2.84 6.064 6.319 6.064zm0-9.063c1.709 0 3.103 1.341 3.103 2.999 0 1.644-1.394 2.985-3.103 2.985S8.896 7.722 8.896 6.078c-.001-1.659 1.393-2.999 3.103-2.999z"
                                      />
                                  </svg>
                              </span>
                          </button>
                      </li>`
                    : ''}
            </ul>
        `;
    }

    render() {
        return html`
            <button type="button" class="ripple btn btn-default btn-sm" aria-label="Поделиться" @click="${this.share}">
                <span class="btn-sm__inner">
                    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.82371 7.04299C4.41798 6.63569 3.86258 6.38462 3.25 6.38462C2.00736 6.38462 1 7.4178 1 8.69231C1 9.96681 2.00736 11 3.25 11C3.86258 11 4.41798 10.7489 4.82371 10.3416M4.82371 7.04299C5.24102 7.46191 5.5 8.04609 5.5 8.69231C5.5 9.33853 5.24102 9.92271 4.82371 10.3416M4.82371 7.04299L8.71389 4.29099M8.71389 4.29099C9.07385 5.07372 9.85025 5.61538 10.75 5.61538C11.9926 5.61538 13 4.5822 13 3.30769C13 2.03319 11.9926 1 10.75 1C9.50736 1 8.5 2.03319 8.5 3.30769C8.5 3.65938 8.5767 3.99269 8.71389 4.29099ZM4.82371 10.3416L8.71389 12.709M8.71389 12.709C8.5767 13.0073 8.5 13.3406 8.5 13.6923C8.5 14.9668 9.50736 16 10.75 16C11.9926 16 13 14.9668 13 13.6923C13 12.4178 11.9926 11.3846 10.75 11.3846C9.85025 11.3846 9.07385 11.9263 8.71389 12.709Z"
                            stroke="#494A52"
                        />
                    </svg>
                </span>
            </button>
            ${this._useNative ? '' : this.renderList()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-web-share': WebShare;
    }
}
