const loaderTemplate = `
    <div class="loader" role="status">
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="34.5" stroke="#494A52" class="loader__circle" />
        </svg>
        <div class="visually-hidden">Загрузка...</div>
    </div>
`;

export function setButtonLoading(el: HTMLButtonElement) {
    const rect = el.getBoundingClientRect();
    el.disabled = true;
    Object.assign(el.style, { width: `${rect.width}px`, height: `${rect.height}px` });
    el.dataset.actualText = el.textContent || '';
    el.innerHTML = loaderTemplate;
}

export function removeButtonLoading(el: HTMLButtonElement) {
    el.disabled = false;
    Object.assign(el.style, { width: '', height: '' });
    el.textContent = el.dataset.actualText || '';
}
