import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';

const footer = document.querySelector('.js-footer');

export default {
    leave({ current }): Promise<void> {
        return new Promise((resolve) => {
            const sections = Array.from(current.container.querySelectorAll('.section'));
            gsap.to(sections, {
                duration: 0.6,
                y: -30,
                opacity: 0,
                ease: 'power3.inOut',
                stagger: 0.1,
                onComplete: () => {
                    setTimeout(resolve, 100);
                },
            });
            gsap.to(footer, {
                duration: 0.6,
                opacity: 0,
                ease: 'power3.inOut',
            });
        });
    },

    enter(): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            gsap.to(footer, {
                duration: 0.6,
                opacity: 1,
                delay: 0.6,
            });

            resolve();
        });
    },

    afterEnter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },
} as ITransitionPage;
