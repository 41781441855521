import barba from '@barba/core';
import delegate from 'delegate';

const hoverableSelector = '.js-hover-text';

function Cursor() {
    let rAF: number;
    let clientX = 0;
    let clientY = 0;

    const cursorContainer = document.createElement('div');
    cursorContainer.className = 'cursor js-cursor';

    const outerCursor = document.createElement('div');
    outerCursor.className = 'cursor__outer';
    outerCursor.textContent = 'See more';
    cursorContainer.appendChild(outerCursor);

    document.body.appendChild(cursorContainer);

    function getMouseCoords(event: MouseEvent) {
        clientX = event.clientX;
        clientY = event.clientY;
    }

    function handleMouseEnter(event: any) {
        const target = event.target.closest(hoverableSelector);
        cursorContainer.classList.add('cursor--visible');

        if (target.dataset.hoverTheme === 'light') {
            cursorContainer.classList.add('cursor--light');
        }

        outerCursor.innerHTML = target.dataset.hoverText || 'See more';
    }

    function handleMouseLeave(event: any) {
        if (!event.target.parentElement.closest(hoverableSelector)) {
            cursorContainer.classList.remove('cursor--visible', 'cursor--light');
        }
    }

    function initHovers() {
        delegate(document, hoverableSelector, 'mouseenter', handleMouseEnter, true);
        delegate(document, hoverableSelector, 'mouseleave', handleMouseLeave, true);
    }

    function render() {
        outerCursor.style.transform = `translate3d(${clientX + -15}px, ${clientY}px, 0) translate3d(-100%, -100%, 0)`;
    }

    function animate() {
        render();
        rAF = requestAnimationFrame(animate);
    }

    function init() {
        document.addEventListener('mousemove', getMouseCoords, { passive: true });
        initHovers();
        rAF = requestAnimationFrame(animate);

        barba.hooks.afterLeave(() => {
            cursorContainer.classList.remove('cursor--visible');
        });
    }

    function destroy() {
        cancelAnimationFrame(rAF);
        document.removeEventListener('mousemove', getMouseCoords);
    }

    return Object.freeze({ init, destroy });
}

export { Cursor };
