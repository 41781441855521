import gsap from 'gsap';

export function createInitialMainPageTimeline(container: HTMLElement | Document = document) {
    const tl = gsap.timeline({
        paused: true,
        defaults: {
            duration: 0.7,
            ease: 'power3.out',
        },
    });

    tl.to(
        container.querySelectorAll('.js-initial-animation-index__circle'),
        { duration: 0.5, opacity: 1 },
        1.2 + 1.5,
    ).fromTo(
        container.querySelectorAll('.js-index-animation-text'),
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, stagger: 0.1 },
        1.3 + 1.5,
    );

    return tl;
}
