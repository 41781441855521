import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export interface Stack {
    el: HTMLElement;
    stacks: HTMLElement[];
    offsets: number[];
    tween: gsap.core.Tween;
    _progress: number;
}

const header = document.querySelector<HTMLElement>('.js-header');

export class Stack {
    constructor(el: HTMLElement, end?: string) {
        gsap.registerPlugin(ScrollTrigger);
        this.el = el;
        this.stacks = Array.from(el.querySelectorAll<HTMLElement>('.js-stack'));
        this.offsets = this.stacks.map((element) => element.offsetTop);
        this._progress = 0;

        this.tween = gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: () => `top top+=${header?.getBoundingClientRect().height || 0}`,
                end: () => end || `+=${this.stacks.reduce((acc, element) => acc + element.offsetHeight, 0)}`,
                pin: matchMedia('(max-width: 1024px)').matches || el.dataset.stacksPin === 'true',
                pinSpacing: false,
                anticipatePin: 1,
                onUpdate: ({ progress }) => {
                    this._progress = progress;
                    this.update(progress);
                },
                onRefresh: () => {
                    for (let i = 1; i < this.stacks.length; i++) {
                        this.stacks[i].style.transform = '';
                    }

                    this.offsets = this.stacks.map((element) => element.offsetTop);
                    this.update(this._progress);
                },
            },
        });
    }

    update(progress: number) {
        for (let i = 1; i < this.stacks.length; i++) {
            const translateLimit = this.offsets[i] - 30 * i;
            this.stacks[i].style.transform = `translate3d(0, ${Math.max(
                -translateLimit,
                progress * (1 / i) * (this.offsets[i - 1] - this.stacks.length * translateLimit),
            )}px, 0)`;
        }
    }

    destroy() {
        this.tween.scrollTrigger?.kill();
        this.tween.kill();
        this.el.style.transform = '';

        for (let i = 1; i < this.stacks.length; i++) {
            this.stacks[i].style.transform = '';
        }
    }
}
