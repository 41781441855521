const videoObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const { target, isIntersecting } = entry;

        if (target instanceof HTMLVideoElement && target.autoplay) {
            if (isIntersecting) {
                target.play();
            } else {
                target.pause();
            }
        }
    });
});

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLVideoElement>('.js-video'));
    elements.forEach((el) => {
        videoObserver.observe(el);
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>('.js-video'));
    elements.forEach((el) => {
        videoObserver.unobserve(el);
    });
}

const _module = { init, destroy };

export default _module;
